<template>
    <section class="authentication authentication-container">
        <div class="authentication-container--login_form">
            <slot />
        </div>
        <slot name="modals" />
    </section>
</template>

<script>

export default {
    name: 'EnteringFormLayout'
}
</script>
